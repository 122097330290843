exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-articles-de-warum-braucht-europa-russland-mdx": () => import("./../../../src/pages/articles/de/warum-braucht-europa-russland.mdx" /* webpackChunkName: "component---src-pages-articles-de-warum-braucht-europa-russland-mdx" */),
  "component---src-pages-articles-index-tsx": () => import("./../../../src/pages/articles/index.tsx" /* webpackChunkName: "component---src-pages-articles-index-tsx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-src-pages-articles-ru-warum-braucht-europa-russland-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/constantindullo/Documents/workspace/WebStorm/untereinemhut/src/pages/articles/ru/warum-braucht-europa-russland.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-tsx-content-file-path-src-pages-articles-ru-warum-braucht-europa-russland-mdx" */),
  "component---src-pages-articles-ru-warum-braucht-europa-russland-mdx": () => import("./../../../src/pages/articles/ru/warum-braucht-europa-russland.mdx" /* webpackChunkName: "component---src-pages-articles-ru-warum-braucht-europa-russland-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

